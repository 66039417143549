import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Разработка правовых документов | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ Практикующий юрист знает все необходимые правила, которые нужно учесть во время подготовки документов." />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/razrabotka-pravovyh-dokumentov'
				name="Разработка правовых документов | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ Практикующий юрист знает все необходимые правила, которые нужно учесть во время подготовки документов."
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Разработка правовых документов" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Разработка правовых документов"}
			
				html={

				`
				<p class="TextText">
				Разработка и правовая экспертиза юридических документов
				</p>

				<p class="TextText">
				Важный процесс в деятельности любой организации — разработка юридических документов, которые сможет без сложностей подписать владелец компании. Сфера деятельности, специализирующихся на формировании бумаг юристов обширна, поэтому важно, чтобы опыт сотрудника соответствовал уровню разрабатываемых документов.
				</p>

				<p class="TextText">
					<b>Разработка правовых документов</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Без правильно оформленной документации невозможно эффективно работать в рамках закона и без риска оказаться в минусе из-за недобросовестных контрагентов. Каждый день может понадобиться помощь в составлении акта, договора, положения, приказа и множества других правовых бумаг. Если подписанная бумага будет составлена неверно, то она юридически не существует.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Кроме того, составленный документ обязательно должен отражать и защищать интересы клиента. Поэтому лучше доверить это дело профессионалу, а не пытаться все учесть самостоятельно — в юриспруденции слишком много нюансов, которые неспециалисту сложно запомнить.
				</p>

				<p class="TextText">
					Практикующий юрист знает все необходимые правила, которые нужно учесть во время подготовки документов.
				</p>

				<p class="TextText">
					<b>Когда стоит обратиться к юристу:</b>
				</p>

				<p class="TextText">
					начало работы фирмы, регистрация документов;
				</p>

				<p class="TextText">
					вопросы в процессе деятельности, подготовка договора с новыми контрагентами;
				</p>

				<p class="TextText">
					составление бумаг для покупки, страховки, аренды;
				</p>

				<p class="TextText">
					защита работодателя — подготовка трудовых договоров, правил внутреннего распорядка;
				</p>

				<p class="TextText">
					сомнения в надежности контрагента.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Но главное — доверяя составление документов специалисту, наши клиенты могут быть уверены в минимизации негативных последствий и потери прибыли из-за неверно составленной документации.
				</p>

				<p class="TextText">
				<b>Правовая экспертиза</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					В любом деле организации или обычного гражданина, нужно взять за правило проверять правильность составления документации, прежде чем подписывать любые соглашения и договоры. Важно контролировать ведение судебного разбирательства и представленных в суде документов, а также обращаться к юристу.
				</p>

				<p class="TextText">
				Когда нужно провести экспертизу:
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					составляется брачный договор — проверить на соответствие законам и возможным изменениям;
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					перед крупной сделкой по недвижимости — поможет уменьшить риск потери денежных средств и на соответствие закону;
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					нужно дополнять заключенный ранее договор дополнительными соглашениями;
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					при любых сомнениях в законности документа и/или ущемлении своих прав.
				</p>

				<p class="TextText">
				В любой ситуации, где не понятно, как составлять бумаги или провести их экспертизу, стоит доверить работу юристам. Тем, кто разбирается в вопросе, имеет большой опыт в разных областях и постоянную практику в юриспруденции.
				</p>

				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
