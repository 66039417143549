import React from "react"
import "./story.module.scss"
import headerStyles from "./story.module.scss"

const Story = () => {

	return (

        <div className={ `uk-section uk-section-medium ${headerStyles.FonRemoveBottom}` }>
            <div className={ `uk-container ${headerStyles.Fon}` } >
            
                <h2 className={ `${headerStyles.sliderHeader}` }>🔥 Наши предложения</h2>

                <div className={ `uk-grid-match uk-child-width-1-1@xl uk-margin-medium-top` } uk-grid="true">
                    <div>
                        <div className={ `uk-flex uk-flex-row uk-flex-center` } >

                            <div uk-slider="autoplay: true; autoplay-interval: 2000;">

                                <div className={ `uk-position-relative` }>

                                    <div className={ `uk-slider-container uk-light` } >
                                        <ul uk-lightbox="autoplay: 2000; animation: scale;" className={ `uk-slider-items uk-child-width-1-2 uk-child-width-1-4@s uk-child-width-1-5@m uk-child-width-1-6@l uk-child-width-1-6@xl` } >

                                            <li className={ `${headerStyles.sliderLi}` }>
                                                <a href="/img/si1.png">
                                                    <img className={ `${headerStyles.sliderImg}` } src="/img/st1.png" alt="Юридическая консультация" ></img>
                                                </a>
                                            </li>

                                            <li className={ `${headerStyles.sliderLi}` }>
                                                <a href="/img/si2.png">
                                                    <img className={ `${headerStyles.sliderImg}` } src="/img/st2.png" alt="Оформление сделок с недвижимостью"></img>
                                                </a>
                                            </li>

                                            <li className={ `${headerStyles.sliderLi}` }>
                                                <a href="/img/si3.png">
                                                    <img className={ `${headerStyles.sliderImg}` } src="/img/st3.png" alt="Подготовка писем, заявлений, запросов"></img>
                                                </a>
                                            </li>

                                            <li className={ `${headerStyles.sliderLi}` }>
                                                <a href="/img/si4.png">
                                                    <img className={ `${headerStyles.sliderImg}` } src="/img/st4.png" alt="Представительство в судах"></img>
                                                </a>
                                            </li>

                                            <li className={ `${headerStyles.sliderLi}` }>
                                                <a href="/img/si5.png">
                                                    <img className={ `${headerStyles.sliderImg}` } src="/img/st5.png" alt="Подготовка искового заявления"></img>
                                                </a>
                                            </li>

                                            <li className={ `${headerStyles.sliderLi}` }>
                                                <a href="/img/si6.png">
                                                    <img className={ `${headerStyles.sliderImg}` } src="/img/st6.png" alt="Юридическое сопровождение сделок"></img>
                                                </a>
                                            </li>

                                            <li className={ `${headerStyles.sliderLi}` }>
                                                <a href="/img/si7.png">
                                                    <img className={ `${headerStyles.sliderImg}` } src="/img/st7.png" alt="Устные и письменные консультации"></img>
                                                </a>
                                            </li>

                                            <li className={ `${headerStyles.sliderLi}` }>
                                                <a href="/img/si8.png">
                                                    <img className={ `${headerStyles.sliderImg}` } src="/img/st8.png" alt="Составление договоров"></img>
                                                </a>
                                            </li>
                                            
                                        </ul>
                                    </div>

                                </div>

                            </div>
                        
                        </div>
                    </div>
                </div>

            </div>
        </div>

	)
}

export default Story


