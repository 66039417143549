import React from "react"
import "./call.module.scss"
import headerStyles from "./policy.module.scss"

const Policy = () => {
	return (
        <div id="modalPolicyContainer" className={ `${headerStyles.modalPolicyContainer}`} >
            <img className={ `uk-margin-small-right` } src="/svg/cookie.svg" width="28" height="28" uk-svg-map="true"></img>

            <p>
                Если, вы остаетесь на сайте, то соглашаетесь с <a href="/obrabotka">политикой конфиденциальности</a>
            </p>

            <div id="modalPolicyClose" className={ `${headerStyles.modalPolicyClose}`}>&times;</div>
        </div>
	)
}
export default Policy
